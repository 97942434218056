import React, { Component } from 'react';
import BackgroundSlider from 'react-background-slider';
import { MuiThemeProvider, Hidden, Paper, Typography } from '@material-ui/core';
import { infoTextTheme } from './Theme';

const images = [...Array(8).keys()]
    .map(i => i + 1)
    .map(i => `/about/${i}.jpg`)

const text = [
    "“Tell me the facts and I’ll learn. Tell me the truth and I’ll believe. But tell me a story and it will live in my heart forever.”",
    "An old Native American proverb",
    "Brother N Sister production specializing in videography and photography, also we focus on message delivery by telling stories.",
    "Storytelling is a fundamental human experience that unites people and drives stronger, deeper connections. Storytelling isn’t just a creative approach to marketing. It gives our customers a totally different entry to their brands."
]

class AboutUs extends Component {

    render() {
        return (<div>
            <MuiThemeProvider theme={infoTextTheme}>
                <div style={{ padding: '30px' }}/>
                <Hidden smDown>
                    <div style={{ padding: '20px' }}/>
                </Hidden>

                <Hidden smDown>
                    <Paper elevation={2} style={{ width: "80%", marginLeft: '50px', marginRight: 'auto', opacity: 0.9, padding: '20px', maxWidth: '700px' }}>
                        <Typography variant="h5" color="textPrimary">{text[0]}</Typography>
                        <Typography variant="body1" color="textSecondary">{text[1]}</Typography>
                        <br/>
                        <Typography variant="h5" color="textPrimary">{text[2]}</Typography>
                        <Typography variant="body1" color="textSecondary">{text[3]}</Typography>
                    </Paper>
                </Hidden>
                <Hidden mdUp>
                    <Paper elevation={2} style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto', opacity: 0.8, padding: '20px', maxWidth: '700px' }}>
                        <Typography variant="h6" color="textPrimary">{text[0]}</Typography>
                        <Typography variant="body2" color="textSecondary">{text[1]}</Typography>
                        <br/>
                        <Typography variant="h6" color="textPrimary">{text[2]}</Typography>
                        <Typography variant="body2" color="textSecondary">{text[3]}</Typography>
                    </Paper>
                </Hidden>

            </MuiThemeProvider>
            <div>
                <BackgroundSlider duration={4} transition={1.5} images={images} />
                <div style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0, background: 'gray', zIndex: -1001 }} />
            </div>
        </div>)
    }

}

export default AboutUs;