import React, { Component } from 'react';
import { Typography } from '@material-ui/core';

class ContactUs extends Component {


    render() {
        return (
            <div>
                <div style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto'}}>
                    <table style={{ borderSpacing: '10px 5px' }}>
                        <tbody>
                            <tr><td><Typography variant='body2'>Mobile</Typography></td><td><Typography variant='body1'>+852 9630 9318</Typography></td></tr>
                            <tr><td><Typography variant='body2'>Office</Typography></td><td><Typography variant='body1'>+852 3996 7624</Typography></td></tr>
                            <tr><td><Typography variant='body2'>E-mail</Typography></td><td><Typography variant='body1'><a href="mailto:fai@bns.hk" target="_top">fai@bns.hk</a></Typography></td></tr>
                            <tr><td><Typography variant='body2'>Studio</Typography></td><td><Typography variant='body1'>Block A, Flat 5, 10/F, Proficient Industrial Centre, 6 Wang Kwun Road, Kowloon Bay, Kowloon</Typography></td></tr>
                        </tbody>
                    </table>
                    <br />
                    <div>
                        <iframe style={{ width: '100%', height: '600px' }} frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0} allowFullScreen={true} title='Block A, Flat 5, 10/F, Proficient Industrial Centre, 6 Wang Kwun Road, Kowloon Bay, Kowloon'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1304.9073621097182!2d114.20742686999468!3d22.32206483763908!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404013377548c61%3A0xa5e60bee3ad5019c!2sProficient+Industrial+Centre%2C+6+Wang+Kwun+Rd%2C+Kowloon+Bay!5e0!3m2!1sen!2shk!4v1552864239973">
                        </iframe>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactUs;

