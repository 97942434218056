import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import ScrollAnimation from 'react-animate-on-scroll';
import './Clients.css'

class Clients extends Component {

    render() {
        const count = 38;
        return (
            <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1000px' }}>
                <div style={{ marginLeft: '16px', marginRight: '16px' }}>
                    <Grid container={true} direction='row' justify='center' spacing={16} style={{ overflowX: 'hidden' }}>
                        {
                            [...Array(count).keys()].map((object, i) => (
                                <Grid key={`image-${i}`} item={true} xs={8} md={5} lg={4}>
                                    <ScrollAnimation animateIn="fadeIn" animateOut='fadeOut' offset={0}>
                                        <div className='image' style={{ backgroundPositionY: i / (count-1) * 100 + '%' }} />
                                    </ScrollAnimation>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>
            </div>)
    }

}

export default Clients;