import React, { Component } from 'react';
import BackgroundSlider from 'react-background-slider';
import { Hidden, Typography, MuiThemeProvider, Paper } from '@material-ui/core';
import { infoTextTheme } from './Theme';

const images = [...Array(9).keys()]
    .map(i => i + 1)
    .map(i => `/service/${i}.jpg`)

const text = [
    "Brother N Sister production is producer, director, script writer, cinematographer, lighting specialist, film editor, sound mixer, music supervisor, art director.",
    "TVC, corporate video, mircrofilm & MV, training video, event video and photos"
]

class Service extends Component {
    render() {
        return (<div>
            <MuiThemeProvider theme={infoTextTheme}>
                <div style={{ padding: '30px' }}/>
                <Hidden smDown>
                    <div style={{ padding: '20px' }}/>
                </Hidden>
                <Hidden smDown>
                    <Paper elevation={2} style={{ width: "80%", marginLeft: '50px', marginRight: 'auto', opacity: 0.9, padding: '20px', maxWidth: '700px' }}>
                        <Typography variant="h5" color="textPrimary">{text[0]}</Typography>
                        <br/>
                        <Typography variant="body1" color="textSecondary">{text[1]}</Typography>
                    </Paper>
                </Hidden>
                <Hidden mdUp>
                    <Paper elevation={2} style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto', opacity: 0.8, padding: '20px', maxWidth: '700px' }}>
                        <Typography variant="h6" color="textPrimary">{text[0]}</Typography>
                        <br/>
                        <Typography variant="body2" color="textSecondary">{text[1]}</Typography>
                    </Paper>
                </Hidden>
            </MuiThemeProvider>
            <div>
                <BackgroundSlider images={images} duration={4} transition={1.5} />
                <div style={{ height: '100%', width: '100%', position: 'absolute', top: 0, left: 0, background: 'gray', zIndex: -1001 }} />
            </div>
        </div>)
    }
}

export default Service;