import React, { Component } from 'react';
import './App.css';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import { Route, Redirect, Switch } from 'react-router';
import { HashRouter, NavLink } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Button, Hidden, Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Clients from './pages/Clients';
import Portfolio from './pages/Portfolio';
import Service from './pages/Service';

const theme = createMuiTheme({
  palette: {
    type: 'light'
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Roboto, Segoe UI, sans-serif'
  },
});

interface IState {
  isMenuOpen: boolean;
}

interface IMenu {
  [id: string]: string;
}

const menus: IMenu = {
  'About Us': '/about/',
  'Services': '/services/',
  'Portfolio': '/portfolio/',
  'Our Clients': '/clients/',
  'Contacts': '/contact/',
}

class App extends Component<any, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isMenuOpen: false,
    };
  }

  render() {
    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <HashRouter>
            <div style={{ flexGrow: 1 }}>
              <AppBar position="fixed" style={{ background: 'white', boxShadow: 'none' }}>
                <Toolbar>

                  <Hidden mdDown>
                    <div style={{ flexGrow: 1, height: '70%' }}>
                      <img src='/logo.jpg' alt='logo' style={{ height: '100%', maxHeight: '54px' }} />
                    </div>
                    {Object.keys(menus).map(menu => (<NavLink to={menus[menu]} key={menu + 'desk'} activeClassName='active'><Button><Typography variant="body1">{menu}</Typography></Button></NavLink>))}
                  </Hidden>

                  <Hidden lgUp>
                    <div style={{ flexGrow: 1, marginTop: '5px', marginBottom: '5px' }}>
                      <img src='/logo.jpg' alt='logo' style={{ height: '100%', maxHeight: '30px' }} />
                    </div>
                    <IconButton color="primary" aria-label="Menu" style={{ marginLeft: -12, marginRight: 0 }} onClick={this.handleMenuOpen}>
                      <MenuIcon />
                    </IconButton>
                    <Drawer open={this.state.isMenuOpen} onClose={this.handleMenuClose} anchor='right'>
                      <List>
                        {Object.keys(menus).map(menu => (<NavLink to={menus[menu]} key={menu + 'mobile'} activeClassName='active'><ListItem button={true} onClick={this.handleMenuClose}><ListItemText primary={menu}/></ListItem></NavLink>))}
                      </List>
                    </Drawer>
                  </Hidden>
                </Toolbar>
              </AppBar>
              <div style={{ marginTop: '72px' }}>
                <Switch>
                  <Route path="/about/" component={AboutUs} />
                  <Route path="/contact/" component={ContactUs} />
                  <Route path="/clients/" component={Clients} />
                  <Route path="/portfolio/" component={Portfolio} />
                  <Route path="/services/" component={Service} />
                  <Redirect to="/about/" />
                </Switch>
              </div>
            </div>
          </HashRouter>
        </MuiThemeProvider>
      </div>
    );
  }

  handleMenuOpen = () => {
    this.setState({ isMenuOpen: true });
  }

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  }
}

export default App;
