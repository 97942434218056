import React, { Component } from 'react';
import './Portfolio.css'
import { Grid, GridListTileBar, Dialog, Hidden } from '@material-ui/core';
import Videos from './Videos';
import ScrollAnimation from 'react-animate-on-scroll';

interface IState {
    selectedVideo: string | undefined
}

class Portfolio extends Component<any, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedVideo: undefined
        };
    }

    render() {
        return (<div>
            <Dialog
                open={!!this.state.selectedVideo}
                onClose={this.handleClose}
                maxWidth={false}
                PaperProps={{style: { width: '90%', maxWidth: '1500px', position: 'relative', margin: 0 }}}
            >
                <div style={{ height: 0, paddingTop: '56.25%' }}/>
                <iframe style={{ width: '100%', height: '100%', position: "absolute", top: 0, left: 0 }} src={`https://www.youtube.com/embed/${this.state.selectedVideo}?autoplay=1`} frameBorder='0' allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} title='video'></iframe>
            </Dialog>
            <div style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto'}}>
                <Hidden smDown>
                    <img src='/storyteller.jpg' alt='logo' style={{ width: '100%' }} />
                </Hidden>
                <Hidden mdUp>
                    <img src='/storyteller_mobile.jpg' alt='logo' style={{ width: '100%' }} />
                </Hidden>
                <div>
                    <Grid container={true} spacing={32}>
                        {Object.keys(Videos).map(key => this.thumbnail(key, Videos[key]))}
                    </Grid>
                </div>
            </div>
        </div>)
    }

    thumbnail = (id: string, desc: string) => {
        return (
            <Grid item={true} key={id} xs={12} sm={12} md={6} lg={4} xl={3}>
                <ScrollAnimation animateIn="fadeIn" animateOut='fadeOut' offset={0}>
                    <div className='youtube-thumbnail' style={{position: 'relative', fontSize: 0}} onClick={this.open(id)}>
                        <img src={`https://img.youtube.com/vi/${id}/mqdefault.jpg`} alt={desc} />
                        <GridListTileBar title={desc} />
                    </div>
                </ScrollAnimation>
            </Grid>
        );
    }

    handleClose = () => {
        this.setState({ selectedVideo: undefined });
    };

    open = (id: string) => () => {
        this.setState({ selectedVideo: id });
    }
}

export default Portfolio;
