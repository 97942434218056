import { createMuiTheme } from "@material-ui/core";

export const infoTextTheme = createMuiTheme({
    palette: {
        type: 'dark'
    },
    typography: {
        useNextVariants: true,
        fontFamily: 'Raleway, Roboto, Segoe UI, sans-serif',
        h6: {
            fontSize: '1rem'
        },
        body2: {
            fontSize: '0.8rem'
        }
    },
});