interface Videos {
    [id: string]: string;
}

const videos: Videos = {
    'FncCbFdQ4ts': 'JaneClare, promo',
    '3Qa0bT4VvZY': 'Cyberport, promo',
    'xNIXZAHdFgc': '方健儀@香港個人資料私隱專員公署, API',
    'Iv3GRO1jeyk': 'Unicef, event 2018 ',
    'bYqteXFGPEg': 'James Ganh, promo',
    '8pS3qv4dEv8': '新家園協會, promo 2016',
    'mhf3oc2GIW8': 'Homely apartments @ Sheffield, promo',
    'uSCwAOQZvZ8': '香港手語協會, promo  ',
    'QzYnUCAVsAw': 'YCH, promo',
    'JGWFiC7xvPs': 'AXA, training video ',
    'CDDcTE8L0kM': 'Security congress, event',
    'FU1838uja48': 'JaneClare x mandy, promo ',
    'Tu6nzjTJjNw': 'HKU MEd, Overview',
    'SF0rAEktpSY': 'Legco, promo',
    'pVn2VICp9lY': 'lena Wei x London, event 2016',
    'FPT0qRfMUcM': '入境處, promo',
    'WS9s6nFHB_0': 'EY, promo',
    'nq8PUrz0wNo': '晴晴的故事, Drama',
    'PAgHJj9pTUQ': 'CUPP, event ',
    'rclzBfFza5w': 'Unicef, event 2017 ',
    'NQn7nMsWaRI': 'Little Dalton, event',
    'TgQfQrnvbYM': 'Make a wish, promo',
    'R6DCORlmETU': '建業順昌, event 2019',
    'S-liOy6gfL4': 'AIA, event 2018',
    'kYby_IJkI9E': 'PolyU x social work, promo 2018',
    '1oEOHMpQeVY': 'Gold coast piazza, event 2019',
    'rU4uXiT3T7w': '永記食品金禧, event',
    'G2wGdVTj6M4': '香港耀能協會, promo',
    'BBWotFTVAjQ': 'Grohe, event 2017 ',
    'ku21dt1-SZI': 'Unicef, promo 2017',
    'pkCRuoNGPYw': 'Unicef, event 2016',
    'ejEmEe_w7RU': 'Security congress, event 2017',
    'e5RoTpALwts': 'Microsoft, promo',
    'Kc7f523VC2c': 'PCCW, promo',
    'k2IIdC7lwa8': 'KPMG, promo',
    'S-43m59Qj_Q': 'JP morgan, promo',
    'y3ql3rfQsbA': 'Jockey club, promo',
    'ODt9_GR0HIc': 'IBM, promo',
    'aoUGOOmhLtU': 'HP, promo',
    'IFBdlJ4UT5E': 'facebook, promo',
    'AhgqlGfrhKc': 'CLP, promo',
    '7hih2inBMho': 'Cathay, promo',
    'ZBjmEDixIxY': 'Alibaba, promo',
    '4RiZ-VqIKd4': '香港耀能協會, promo 2017',
    'umtivv0KxSY': 'fintech o2o global summit, event ',
    'AMjJWJGFumM': 'puma x liger, event',
    'bUbsc6dZTiY': '聖詩會2010, live event',
    'eMvi9Z4_bZI': '當你走到無力, MV',
    'PuOQqSr0GJg': '活學教育, promo',
    '9mOJWTj6sPw': '天國近了, MV',
    'djtYHK2rI7E': 'Wild kids, promo',
    'XU1vbbTagPE': '方健儀@香港個人資料私隱專員公署, Education',
    'Mj6cevi965Y': 'Life Adventure@mtr, promo',
    'fCQIjS-5U6E': 'Konica Minolta, event',
    'dqKXcXReQ8w': 'Bless juice x The Cept, promo',
    'eFWRZOtKlUg': 'Island youth camp, event 2015',
    'P-W-msDVeVo': 'IP TriChallenge, event 2015',
    'o_vp8Ehk6KA': '愛梨巴慈善基金, promo',
    'usS77m8EVtE': 'governor interview',
    'xp6Jf8ROI_Y': 'HKU BscIM, Promo',
    'kix9lxIm1LQ': 'Carlsberg, tranning video',
    'lYDSqFLMm7A': '華恩基金會, promo',
    'xb7UygdBBJ4': '一步護一生',
    'v0ohET_ZI_s': 'HKU MEd, promo',
    'bJ5vS-FjEgY': 'Education Bureau Central resources, promo',
    'wG9zCckj-wQ': 'Family kichen, event',
    'dS_C7_10yFM': 'BNS sport photo ',
}

export default videos;